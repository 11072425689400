

// Colors
$color_BackLightBlue: rgba(3,139,173,1);
$color_BackMidBlue: rgba(16,72,121,1);
$color_BackDarkBlue: rgba(24,34,66,1);
$color_EmphasisLightOrange: rgba(247,187,136,1);
$color_EmphasisDarkOrange: rgba(241,145,132,1);
$color_Footer: rgba(73,178,201,1);
$color_TextContainer: rgba($color: #3e9fbe, $alpha: .4);

// Gradients
$gradient_backgroundGradient: linear-gradient(93deg, rgba(24,34,66,1) 0%, rgba(16,72,121,1) 47%, rgba(3,139,173,1) 100%);
$gradient_buttonsGradient: linear-gradient(93deg, $color_EmphasisLightOrange 0%, $color_EmphasisDarkOrange 100%);

//Fonts
@font-face {
    font-family: lato-regular;
    src: url('../Assets/Fonts/Lato-Regular.ttf');
    font-display: swap;
}
$font_defaultFont: lato-regular, Arial, Helvetica, sans-serif;