@use '../Styles/mixins' as mixins;


.Body{
    margin-top: 50px;
    margin-bottom: 10px;
}

.singleTextContainer{
    @include mixins.SingleTextContainer();
}

.multipleTextContainer{
    @include mixins.MultipleTextContainer();
}