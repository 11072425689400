@use '../Styles/variables' as vars;
@use '../Styles/mixins' as mixins;
@use '../Styles/animations' as anim;

footer{
    background-color: vars.$color_Footer;
    font-family: vars.$font_defaultFont;
    margin-bottom: 0px;
    width: 100vw;
}

.changeLanguageButton{
    background-color: rgba(243, 243, 243, 0);
    border: none;
    cursor: pointer;
    @include anim.AnimAll();
}

.changeLanguageButton:hover{
    background-color: vars.$color_BackLightBlue;
}


footer.fullScreen {
    height: 38px;

    .language {
        color: black;
        font-size: 18px;
        float: right;
        margin-top: 8px;
        padding-left: 5px;
        padding-right: 30px;
    }

    .rights{
        color: black;
        font-size: 13px;
        float: right;
        margin-top: 13px;
        margin-left: 2px;
        margin-right: 15px;
    }

    .icon{
        width: 30px;
        height: 30px;
        margin: 5px;
    }

    .iconLeft {
        float: left;
        margin-left: 10px;
    }

    .iconRight {
        float: right;
    }

    a{
        @include anim.AnimAll();
        padding-left: 3px;
        padding-right: 3px;
    }

    a:hover {
        background-color: vars.$color_EmphasisDarkOrange;
    }

}


footer.midScreen {
    height: 35px;

    .language {
        margin-top: 8px;
        float: right;
        padding-right: 9px;
    }

    .rights{
        color: black;
        font-size: 13px;
        float: left;
        margin-top: 11px;
        padding-right: 5px;
        padding-left: 5px;
    }

    .icon{
        width: 25px;
        height: 25px;
        margin: 5px;
    }

    .iconLeft {
        float: left;
    }

    .iconRight {
        float: right;
    }

    a{
        @include anim.AnimAll();
        padding-left: 1px;
        padding-right: 1px;
    }

    a:hover {
        background-color: vars.$color_EmphasisDarkOrange;
    }

}


footer.miniScreen {
    height: 25px;
    padding-top: 0px;
    padding-left: 0px;

}


.stickyBottom {
    position: fixed;
    bottom: 0;
    left: 0;
}



// Region Offcanvas

.offcanvasCustomFooter {
  
    .offcanvasButtonFooter {
        background-color: rgba(243, 243, 243, 0);
        border: none;
        cursor: pointer;
        width: 100vw;
        @include anim.AnimAll();
    
        .offcanvasButtonIconFooter{
            height: 25px;
        }
  
    }

    .offcanvasButtonFooter:hover {
        background-color: vars.$color_BackLightBlue;
      }
  
}


  
.offcanvasPanelFooter{
    background-color: vars.$color_BackLightBlue;
    height: 220px;
}
  
.offcanvasTitleFooter{

    .rights{
        color: black;
        font-family: vars.$font_defaultFont;
        font-size: 13px;
        text-decoration: none;
        padding: 10px;
        @include anim.AnimAll();
    }

    a{
        text-decoration: none;
    }

    .rights:hover {
        background-color: vars.$color_EmphasisDarkOrange;
        color:rgb(0, 0, 0);
      }

}
  
.offcanvas-contentFooter{

    a {
      color:rgb(243, 243, 243);
      padding: 12px 12px;
      margin-bottom: 10px;
      text-decoration: none;
      -webkit-transition:all 500ms ease;
      -o-transition:all 500ms ease;
      transition:all 500ms ease;
    }
  
    a:hover {
      background-color: vars.$color_EmphasisDarkOrange;
      color:rgb(0, 0, 0);
    }
  
    .Selected {
      background: vars.$color_EmphasisLightOrange;
      color:rgb(0, 0, 0);
      background: vars.$gradient_buttonsGradient;
      font-weight: bold;
    }

    .icon{
        width: 30px;
        height: 30px;
        margin: 5px;
    }
  
}
  
  // Endregion Offcanvas