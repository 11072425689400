@use '../Styles/variables' as vars;
@use '../Styles//mixins' as mixins;
@use '../Styles/animations' as anim;

header.fullScreen{
  height: 80px;

  .logo {
    font-size:10px;
    margin-top: 22px;
    margin-left: 30px;
    height: 45px;
  }

  nav {
    float:right;
    height: 70px;
    margin-right: 5vw;
  }

  nav a {
    margin-top: 30px;
    display:inline-block;
    color:rgb(243, 243, 243);
    text-decoration:none;
    padding:10px 20px;
    line-height:normal;
    font-size:12px;
    letter-spacing: 2px;
    @include anim.AnimAll();
    border-top: 2px solid;
    border-top-color: rgba(241,145,132,0);
    font-weight: bold;
  }

  nav .NoSelected:hover {
    background-color: vars.$color_EmphasisDarkOrange;
    color:rgb(0, 0, 0);
  }

  nav .Selected {
    background: vars.$color_EmphasisLightOrange;
    color:rgb(0, 0, 0);
    background: vars.$gradient_buttonsGradient;
  }


}

header.miniScreen{
  height: 50px;

  .logo {
    font-size:10px;
    margin-top: 11px;
    margin-left: 5vw;
    height: 30px;
  }

}

header {
  @include mixins.SetBlueBackground();
}



.stickyTop {
  position: fixed;
  top: 0;
  width: 100vw;
}



// Region Offcanvas

.offcanvasCustomHeader {
  float: right;
  margin-right: 5vw;
  margin-top: 5px;

  .offcanvasButtonHeader {
    background-color: rgba(243, 243, 243, 0);
    border: none;
    cursor: pointer;
    @include anim.AnimAll();

    .offcanvasButtonIconHeader{
      height: 35px;
    }

  }

  .offcanvasButtonHeader:hover {
    background-color: vars.$color_BackLightBlue;
  }

}

.offcanvasPanelHeader{
  background-color: vars.$color_BackLightBlue;
  width: 300px;
}

.offcanvasTitleHeader{
  color: white;
  font-family: vars.$font_defaultFont;
}

.offcanvas-contentHeader{
  a {
    color:rgb(243, 243, 243);
    padding: 12px 20px;
    text-decoration: none;
    display: block;
    font-size:12px;
    letter-spacing: 2px;
    @include anim.AnimAll();
    font-weight: bold;
  }

  .NoSelected:hover {
    background-color: vars.$color_EmphasisDarkOrange;
    color:rgb(0, 0, 0);
  }

  .Selected {
    background: vars.$color_EmphasisLightOrange;
    color:rgb(0, 0, 0);
    background: vars.$gradient_buttonsGradient;
  }

}

// Endregion Offcanvas