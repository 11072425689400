@use './variables' as vars;

@mixin SetBlueBackground() {
    background: vars.$color_BackMidBlue;
    background: vars.$gradient_backgroundGradient;
}

@mixin MultipleTextContainer() {
    background-color: vars.$color_TextContainer;
    margin-left: 8vw;
    margin-right: 8vw;
    padding: 5vw;
    border-radius: 8px;
    margin-top: 5vw;
    margin-bottom: 5vw;
}

@mixin SingleTextContainer() {
    background-color: vars.$color_TextContainer;
    margin-left: 8vw;
    margin-right: 8vw;
    padding: 5vw;
    border-radius: 8px;
    margin-top: 10vw;
    margin-bottom: 5vw;
}